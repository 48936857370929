import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import useEnglish from "../library/useEnglish"
import TimetablePage from "../pagesLayouts/TimetablePage"
import { WordpressPageQuery } from "../types/schema"

const translation = {
  navigation: {
    left: "CLASSES",
    right: "TEAM",
  },
}

export default function TimetableEn() {
  useEnglish()

  const query = graphql`
    query {
      wpPage(language: { slug: { eq: "en" } }, slug: { eq: "timetable-en" }) {
        ...WordpressPageFragment
      }
    }
  `
  const data = useStaticQuery<WordpressPageQuery>(query)

  return <TimetablePage data={data} translation={translation} />
}
